import axios from "@/services/api";
import {
  ALL_OFF_DAYS,
  ALL_OFF_DAYS_BY_MONTH,
  CHANGE_OFF_DAYS_STATUS,
  OFF_DAY_CREATE,
  OFF_DAY_UPDATE,
  OFF_DAY_REMOVE,
} from "@/store/actions/offDays.js";

const state = {
  offDays: null,
};

const getters = {
  allOffDays: (state) => state.offDays,
};

const actions = {
  [ALL_OFF_DAYS]: ({ commit, dispatch }) => {
    return new Promise((resolve) => {
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get("/user/off-day-requests")
        .then((resp) => {
          if (resp.status === 200) {
            const offDays = resp.data.data.off_day_requests;
            commit("ALL_OFF_DAYS", { offDays });
          }
          resolve(resp);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [OFF_DAY_REMOVE]: ({ commit }, id) => {
    return new Promise((resolve, reject) => {
      axios
        .delete("/user/off-day-requests/" + id)
        .then((response) => {
          const offDays = response.data.data.off_day_requests;
          commit("ALL_OFF_DAYS", { offDays });
          resolve(response);
        })
        .catch((error) => {
          commit("OFF_DAY_GET_ALL_ERROR", { error: error });
          reject(error);
        });
    });
  },

  [CHANGE_OFF_DAYS_STATUS]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .put("/user/off-day-requests/bulk-actions", params)
        .then((response) => {
          const offDays = response.data.data.off_day_requests;
          commit("ALL_OFF_DAYS", { offDays });
          resolve(response);
        })
        .catch((error) => {
          commit("USER_GET_ERROR", { error: error });

          reject(error);
        });
    });
  },

  [OFF_DAY_CREATE]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/user/off-day-requests", data)
        .then((response) => {
          const offDays = response.data.data.off_day_requests;
          commit("ALL_OFF_DAYS", { offDays });
          resolve(response);
        })
        .catch((error) => {
          commit("OFF_DAY_GET_ALL_ERROR", { error: error });

          reject(error);
        });
    });
  },

  [OFF_DAY_UPDATE]: ({ commit }, data) => {
    console.log(data);
    return new Promise((resolve, reject) => {
      axios
        .post("/user/offday/update", data)
        .then((response) => {
          const offDays = response.data.data.off_day_requests;
          commit("ALL_OFF_DAYS", { offDays });
          resolve(response);
        })
        .catch((error) => {
          commit("OFF_DAY_GET_ALL_ERROR", { error: error });

          reject(error);
        });
    });
  },

  [ALL_OFF_DAYS_BY_MONTH]: ({ commit, dispatch }, params) => {
    return new Promise((resolve) => {
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .post("/user/off-day-requests/get-by-month", params)
        .then((resp) => {
          if (resp.status === 200) {
            const offDays = resp.data.data.off_day_requests;
            commit("ALL_OFF_DAYS", { offDays });
          }
          resolve(resp);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
};

const mutations = {
  [ALL_OFF_DAYS]: (state, params) => {
    state.offDays = params.offDays;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
