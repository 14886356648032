import axios from "@/services/api";
import {
  REQUEST_PHOTO,
  PHOTO_GET_ALL,
  PHOTO_GET_ALL_SUCCESS,
  PHOTO_GET_ALL_ERROR,
  PHOTO_GET,
  PHOTO_GET_SUCCESS,
  PHOTO_GET_ERROR,
  PHOTO_CREATE,
  PHOTO_GET_ALL_BY_CUSTOMER_ID,
  PHOTO_UPDATE,
  PHOTO_DELETE,
} from "@/store/actions/photo";

function initializeState() {
  return {
    photos: [],
    pagination: {},
  };
}

const state = initializeState();

const getters = {
  photos: state => state.photos,
  photosPagination: state => state.pagination,
  photo: state => state.photoDetails,
};

const actions = {
  [PHOTO_GET_ALL]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("REQUEST_PHOTO");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/user/photos`, params)
        .then(response => {
          commit("PHOTO_GET_ALL_SUCCESS", {
            photos: response.data.data.photos,
            pagination: response.data.paginate
          });

          resolve(response);
        })
        .catch(error => {
          commit("PHOTO_GET_ALL_ERROR");

          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  [PHOTO_GET_ALL_BY_CUSTOMER_ID]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("REQUEST_PHOTO");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/user/photos/` + params.customer_id + `/by-customer-id`, {
          params: params
        })
        .then(response => {
          commit("PHOTO_GET_ALL_SUCCESS", {
            photos: response.data.data.photos,
            pagination: response.data.paginate
          });

          resolve(response);
        })
        .catch(error => {
          commit("PHOTO_GET_ALL_ERROR");

          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [PHOTO_CREATE]: ({ commit, dispatch }, data) => {
    return new Promise((resolve, reject) => {
      commit("REQUEST_PHOTO");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .post(`/user/photos`, data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(response => {
          commit("PHOTO_GET_SUCCESS", response.data);

          resolve(response);
        })
        .catch(error => {
          commit("PHOTO_GET_ERROR");

          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [PHOTO_UPDATE]: ({ commit, dispatch }, {id, data}) => {
    return new Promise((resolve, reject) => {
      commit("REQUEST_PHOTO");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .post(`/user/photos/` + id, data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          commit("PHOTO_GET_SUCCESS", response.data);

          resolve(response);
        })
        .catch(error => {
          commit("PHOTO_GET_ERROR");

          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  [PHOTO_GET]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("REQUEST_PHOTO");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/user/photos/${params.id}`, params)
        .then(response => {
          commit("PHOTO_GET_SUCCESS", response.data);

          resolve(response);
        })
        .catch(error => {
          commit("PHOTO_GET_ERROR");

          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  [PHOTO_DELETE]: ({ commit, dispatch }, id) => {
    return new Promise((resolve, reject) => {
      commit("REQUEST_PHOTO");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .delete('/user/photos/' + id)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          commit("PHOTO_GET_ERROR");

          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  }
};

const mutations = {
  [REQUEST_PHOTO]: state => {
    state.status = "loading";
  },

  [PHOTO_GET_ALL_SUCCESS]: (state, params) => {
    state.status = "success";
    state.photos = params.photos;
    state.pagination = params.pagination;
  },

  [PHOTO_GET_ALL_ERROR]: state => {
    state.status = "error";
  },

  [PHOTO_GET_SUCCESS]: (state, params) => {
    state.status = "success";
    state.photoDetails = params.data.photo;
  },

  [PHOTO_GET_ERROR]: state => {
    state.status = "error";
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
