import axios from "axios";
import {
  VISIT_MEMO_GET_ALL,
  VISIT_MEMO_GET_ALL_SUCCESS,
  VISIT_MEMO_GET_ALL_ERROR,
  VISIT_MEMO_GET_BY_ID,
  VISIT_MEMO_CREATE,
  VISIT_MEMO_EDIT,
  VISIT_MEMO_DELETE
} from "@/store/actions/visitMemo"

function initialState() {
  return {
    status: "",
    visitMemos: [],
    visitMemosPagination: {},
    visitMemo: []
  }
}

const state = initialState()

const getters = {
  allVisitMemos: state => state.visitMemos,
  visitMemosPagination: state => state.visitMemosPagination,
  visitMemoById: state => state.visitMemo
}

const actions = {
  [VISIT_MEMO_GET_ALL]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get('/user/visit-memo', {
          params: params
        })
        .then(response => {
          let data = response.data
          commit('VISIT_MEMO_GET_ALL_SUCCESS', {
            visitMemos: data.data.visit_memos,
            visitMemosPagination: data.paginate
          })

          resolve(response)
        })
        .catch(error => {
          commit('VISIT_MEMO_GET_ALL_ERROR', { error: error })
          reject(error)
        })
    })
  },
  [VISIT_MEMO_GET_BY_ID]: ({ commit, dispatch }, params) => {
    dispatch("API_PROCESSING", true, { root: true });
    return new Promise((resolve, reject) => {
      axios
        .get(`/user/visit-memo/${params}`)
        .then(response => {
          let data = response.data
          commit('VISIT_MEMO_GET_BY_ID', {
            visitMemo: data.data.visit_memo
          })
          resolve(response)
        })
        .catch(error => {
          commit('VISIT_MEMO_GET_ALL_ERROR', { error: error })
          reject(error)
        })
    })
  },
  [VISIT_MEMO_CREATE]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/user/visit-memo', data)
        .then(response => {
          let data = response.data
          commit('VISIT_MEMO_GET_BY_ID', {
            visitMemo: data.data.visit_memo
          });
          resolve(response)
        })
        .catch(error => {
          commit('VISIT_MEMO_GET_ALL_ERROR', { error: error })
          reject(error)
        })
    })
  },
  [VISIT_MEMO_EDIT]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/user/visit-memo/${data.visit_id}`, data)
        .then(response => {
          let data = response.data
          commit('VISIT_MEMO_GET_BY_ID', {
            visitMemo: data.data.visit_memo
          });
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  [VISIT_MEMO_DELETE]: ({ commit }, { data, visit_id }) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/user/visit-memo/` + visit_id, data)
        .then(response => {
          let data = response.data
          commit('VISIT_MEMO_GET_BY_ID', {
            visitMemo: data.data.visit_memo
          });
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

const mutations = {
  [VISIT_MEMO_GET_ALL_SUCCESS]: (state, params) => {
    state.status = "success"
    state.visitMemos = params.visitMemos
    state.visitMemosPagination = params.visitMemosPagination
  },
  [VISIT_MEMO_GET_ALL_ERROR]: state => {
    state.status = 'error'
  },
  [VISIT_MEMO_GET_BY_ID]: (state, params) => {
    state.status = 'success'
    state.visitMemo = params.visitMemo
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
