import axios from "@/services/api";
import {
  SERVICE_GET,
  SERVICE_GET_SUCCESS,
  SERVICE_GET_ALL,
  SERVICE_GET_ALL_SUCCESS
} from "@/store/actions/service";

const state = {
  service: null,
  services: []
};

const getters = {
  getService: state => state.service,
  allServices: state => state.services
};

const actions = {
  [SERVICE_GET_ALL]: ({ commit, dispatch }) => {
    return new Promise(resolve => {
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get("/admin/services")
        .then(resp => {
          if (resp.status === 200) {
            const services = resp.data.data.services;
            commit("SERVICE_GET_ALL_SUCCESS", { services });
          }
          resolve(resp);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  [SERVICE_GET]: ({ commit, dispatch }, id) => {
    return new Promise(resolve => {
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get("/admin/services/" + id)
        .then(resp => {
          if (resp.status === 200) {
            const service = resp.data.data.service;
            commit("SERVICE_GET_SUCCESS", { service });
          }
          resolve(resp);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  }
};

const mutations = {
  [SERVICE_GET_SUCCESS]: (state, params) => {
    state.service = params.service;
  },
  [SERVICE_GET_ALL_SUCCESS]: (state, params) => {
    state.services = params.services;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
