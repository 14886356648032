export const SETTINGS_REQUEST = "SETTINGS_REQUEST";
export const SETTINGS_PREFERENCES_GET = "SETTINGS_PREFERENCES_GET";
export const SETTINGS_PREFERENCES_GET_SUCCESS =
  "SETTINGS_PREFERENCES_GET_SUCCESS";
export const SETTINGS_PREFERENCES_GET_ERROR = "SETTINGS_PREFERENCES_GET_ERROR";

export const SETTINGS_PREFERENCES_UPDATE = "SETTINGS_PREFERENCES_UPDATE";
export const SETTINGS_PREFERENCES_UPDATE_SUCCESS =
  "SETTINGS_PREFERENCES_UPDATE_SUCCESS";
export const SETTINGS_PREFERENCES_UPDATE_ERROR =
  "SETTINGS_PREFERENCES_UPDATE_ERROR";
