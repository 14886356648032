<template>
  <svg
    id="レイヤー_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="14px"
    height="14px"
    viewBox="0 0 44 44"
    enable-background="new 0 0 44 44"
    xml:space="preserve"
  >
    <circle
      fill-rule="evenodd"
      clip-rule="evenodd"
      fill="#BCBCBC"
      cx="22"
      cy="21.998"
      r="22"
    />
    <g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        fill="#FFFFFF"
        d="M10.744,16.869c0.148-1.017,0.211-1.961,0.428-2.869
		c0.906-3.821,3.143-6.495,6.929-7.735c2.16-0.707,4.379-0.654,6.594-0.295c2.007,0.323,3.856,1.043,5.401,2.406
		c1.832,1.617,2.795,3.657,2.837,6.107c0.058,3.336-1.33,5.917-4.103,7.775c-0.731,0.49-1.436,1.03-2.101,1.604
		c-1.331,1.149-2.104,2.56-1.96,4.39c0.053,0.684,0.009,0.687-0.678,0.687c-1.654,0-3.31,0.002-4.964-0.003
		c-0.212,0-0.447,0.058-0.46-0.298c-0.062-1.943,0.083-3.841,1.023-5.6c0.714-1.337,1.791-2.334,2.969-3.254
		c0.965-0.753,1.967-1.468,2.619-2.554c0.927-1.546,0.712-4.076-0.495-5.248c-1.039-1.007-2.345-1.125-3.664-0.868
		c-1.928,0.377-3.19,1.872-3.496,3.974c-0.068,0.465-0.088,0.939-0.173,1.402c-0.027,0.14-0.195,0.361-0.299,0.361
		C15.075,16.875,12.997,16.869,10.744,16.869z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        fill="#FFFFFF"
        d="M21.577,38.592c-1.989,0-3.529-1.54-3.524-3.526
		c0-1.927,1.582-3.5,3.524-3.507c1.946-0.009,3.521,1.582,3.52,3.553C25.096,37.063,23.55,38.592,21.577,38.592z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "QuestionMarkIcon"
};
</script>

<style scoped></style>
