import axios from "@/services/api";
import {
  SITE_GET_ALL,
  SITE_GET_ALL_SUCCESS,
  SITE_GET_ERROR,
} from "@/store/actions/site";

const state = {
  sites: null,
  siteTasks: [],
  serviceTypes: [],
  allsites: [],
};

const getters = {
  getSites: (state) => state.sites,
  getSitePagination: (state) => state.sitePagination,
};

const actions = {
  [SITE_GET_ALL]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get("/user/sites", {
          params: params,
        })
        .then((response) => {
          let data = response.data;
          commit("SITE_GET_ALL_SUCCESS", {
            sites: data.data.sites,
            sitePagination: data.paginate,
          });

          resolve(response);
        })
        .catch((error) => {
          commit("SITE_GET_ALL_ERROR", { error: error });

          reject(error);
        });
    });
  },
};

const mutations = {
  [SITE_GET_ALL_SUCCESS]: (state, params) => {
    state.status = "success";
    state.sites = params.sites;
    state.sitePagination = params.sitePagination;
  },
  [SITE_GET_ERROR]: (state) => {
    state.status = "error";
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
