export const CUSTOMER_GET_ALL = "CUSTOMER_GET_ALL";
export const CUSTOMER_GET_ALL_SUCCESS = "CUSTOMER_GET_ALL_SUCCESS";
export const CUSTOMER_GET = "CUSTOMER_GET";
export const CUSTOMER_GET_SUCCESS = "CUSTOMER_GET_SUCCESS";
export const CUSTOMER_GET_ERROR = "CUSTOMER_GET_ERROR";
export const CUSTOMER_TASK_GET = 'CUSTOMER_TASK_GET';
export const CUSTOMER_TASK_GET_SUCCESS = 'CUSTOMER_TASK_GET_SUCCESS';
export const CUSTOMER_TASK_GET_ERROR = 'CUSTOMER_TASK_GET_ERROR';
export const GET_SERVICE_TYPE = 'GET_SERVICE_TYPE';
export const GET_SERVICE_SUCCESS = 'GET_SERVICE_SUCCESS';
export const GET_SERVICE_ERROR = 'GET_SERVICE_ERROR';
export const CUSTOMER_TASKS_FILTER = "CUSTOMER_TASKS_FILTER";
export const CUSTOMER_CREATE_CLAIM = "CUSTOMER_CREATE_CLAIM";
export const CUSTOMER_EDIT_CLAIM = "CUSTOMER_EDIT_CLAIM";
export const CUSTOMER_DELETE_CLAIM = "CUSTOMER_DELETE_CLAIM";
export const CUSTOMER_CLAIM_GET_SUCCESS = "CUSTOMER_CLAIM_GET_SUCCESS";
export const ALL_CUSTOMER = "ALL_CUSTOMER";
export const ALL_CUSTOMER_SUCCESS = "ALL_CUSTOMER_SUCCESS";
export const ALL_CUSTOMER_ERROR = "ALL_CUSTOMER_ERROR";
export const GET_SITE_BY_CUSTOMER = "GET_SITE_BY_CUSTOMER";
export const CUSTOMER_GET_ALL_AFTER_SERVICE = "CUSTOMER_GET_ALL_AFTER_SERVICE";
export const CUSTOMER_GET_ALL_AFTER_SERVICE_SUCCESS = "CUSTOMER_GET_ALL_AFTER_SERVICE_SUCCESS";
export const CUSTOMER_GET_ALL_AFTER_SERVICE_ERROR = "CUSTOMER_GET_ALL_AFTER_SERVICE_ERROR";
