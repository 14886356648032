export const USER_REQUEST = "USER_REQUEST";
export const USER_REPORT_GET = "USER_REPORT_GET";
export const USER_REPORT_GET_SUCCESS = "USER_REPORT_GET_SUCCESS";
export const USER_REPORT_GET_ERROR = "USER_REPORT_GET_ERROR";

export const USER_CHANGE_PASSWORD = "USER_CHANGE_PASSWORD";
export const USER_CHANGE_PASSWORD_SUCCESS = "USER_CHANGE_PASSWORD_SUCCESS";
export const USER_CHANGE_PASSWORD_ERROR = "USER_CHANGE_PASSWORD_ERROR";
export const USER_GET_ALL = "USER_GET_ALL";
export const USER_GET_ALL_SUCCESS = "USER_GET_ALL_SUCCESS";
export const USER_GET_ALL_ERROR = "USER_GET_ALL_ERROR";