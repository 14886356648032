export const REQUEST_PHOTO = "REQUEST_PHOTO";
export const PHOTO_GET_ALL = "PHOTO_GET_ALL";
export const PHOTO_GET_ALL_SUCCESS = "PHOTO_GET_ALL_SUCCESS";
export const PHOTO_GET_ALL_ERROR = "PHOTO_GET_ALL_ERROR";

export const PHOTO_GET = "PHOTO_GET";
export const PHOTO_GET_SUCCESS = "PHOTO_GET_SUCCESS";
export const PHOTO_GET_ERROR = "PHOTO_GET_ERROR";

export const PHOTO_CREATE = "PHOTO_CREATE";

export const PHOTO_GET_ALL_BY_CUSTOMER_ID = "PHOTO_GET_ALL_BY_CUSTOMER_ID";

export const PHOTO_UPDATE = "PHOTO_UPDATE";
export const PHOTO_DELETE = "PHOTO_DELETE";