import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import weekday from "dayjs/plugin/weekday";
import duration from "dayjs/plugin/duration";
import "dayjs/locale/ja";

dayjs.extend(updateLocale);
dayjs.extend(isSameOrBefore);
dayjs.extend(weekday);
dayjs.extend(duration);

dayjs.updateLocale("ja", {
  weekdays: ["日", "月", "火", "水", "木", "金", "土"]
});

dayjs.locale("ja");

export default dayjs;
