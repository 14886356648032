export const WORKTIMES = "WORKTIMES";
export const CLOCK_IN = "CLOCK_IN";
export const CLOCK_OUT = "CLOCK_OUT";
export const WORKTIME_GET = "WORKTIME_GET";
export const WORKTIME_BY_TASK_ID_GET = "WORKTIME_BY_TASK_ID_GET";
export const CREATE_NEW_WORKTIME = "CREATE_NEW_WORKTIME";
export const UPDATE_WORKTIME = "UPDATE_WORKTIME";
export const DELETE_WORKTIME = "DELETE_WORKTIME";
export const COUNT_PENDING_WORKTIME = "COUNT_PENDING_WORKTIME";
export const CREATE_WORKTIME_REST = "CREATE_WORKTIME_REST";
export const WORKTIME_GET_SUCCESS = "WORKTIME_GET_SUCCESS";
export const WORKTIME_GET_ERROR = "WORKTIME_GET_ERROR";
export const WORKTIME_OPERATIONAL_HOURS_GET = "WORKTIME_OPERATIONAL_HOURS_GET";
export const WORKTIME_OPERATIONAL_HOURS_GET_SUCCESS =
  "WORKTIME_OPERATIONAL_HOURS_GET_SUCCESS";
export const WORKTIME_OPERATIONAL_HOURS_GET_ERROR =
  "WORKTIME_OPERATIONAL_HOURS_GET_ERROR";
