import axios from "@/services/api";
import {
  CUSTOMER_GET_ALL,
  CUSTOMER_GET,
  CUSTOMER_GET_SUCCESS,
  CUSTOMER_GET_ALL_SUCCESS,
  CUSTOMER_GET_ERROR,
  CUSTOMER_TASK_GET,
  CUSTOMER_TASK_GET_SUCCESS,
  CUSTOMER_TASK_GET_ERROR,
  GET_SERVICE_TYPE,
  GET_SERVICE_SUCCESS,
  GET_SERVICE_ERROR,
  CUSTOMER_TASKS_FILTER,
  CUSTOMER_CREATE_CLAIM,
  CUSTOMER_EDIT_CLAIM,
  CUSTOMER_DELETE_CLAIM,
  CUSTOMER_CLAIM_GET_SUCCESS,
  ALL_CUSTOMER,
  ALL_CUSTOMER_SUCCESS,
  ALL_CUSTOMER_ERROR,
  GET_SITE_BY_CUSTOMER,
  CUSTOMER_GET_ALL_AFTER_SERVICE,
  CUSTOMER_GET_ALL_AFTER_SERVICE_SUCCESS,
  CUSTOMER_GET_ALL_AFTER_SERVICE_ERROR
} from "@/store/actions/customer";

const state = {
  customer: null,
  customers: null,
  customerTasks: [],
  serviceTypes: [],
  allcustomers: [],
  afterServices: [],
  afterServicePagination: {}
};

const getters = {
  getCustomer: state => state.customer,
  getCustomers: state => state.customers,
  getCustomerPagination: state => state.customerPagination,
  customerTasksById: state => state.customerTasks,
  getServiceTypes: state => state.serviceTypes,
  allCustomers: state => state.allcustomers,
  getCustomerAfterServices: state => state.afterServices,
  getCustomerAfterServicePagination: state => state.afterServicePagination,
  selectedProject: state => state.selectedProject,
  afterServices: state => state.afterServices,
  afterServicePagination: state => state.afterServicePagination
};

const actions = {
  [CUSTOMER_GET_ALL]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get("/user/customer", {
          params: params
        })
        .then(response => {
          let data = response.data;
          commit("CUSTOMER_GET_ALL_SUCCESS", {
            customers: data.data.customers,
            customerPagination: data.paginate
          });

          resolve(response);
        })
        .catch(error => {
          commit("CUSTOMER_GET_ALL_ERROR", { error: error });

          reject(error);
        });
    });
  },

  [GET_SITE_BY_CUSTOMER]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`user/customers/${params.id}/sites`)
        .then(response => {
          let data = response.data;
          commit("SITE_GET_ALL_SUCCESS", {
            sites: data.data.sites
          });
          resolve(response);
        })
        .catch(error => {
          commit("SITE_GET_ALL_ERROR", { error: error });

          reject(error);
        });
    });
  },

  [CUSTOMER_GET]: ({ commit, dispatch }, params) => {
    return new Promise(resolve => {
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get("/user/customer/" + params.customer_id , {params})
        .then(resp => {
          if (resp.status === 200) {
            const customer = resp.data.data.customer;
            commit("CUSTOMER_GET_SUCCESS", { customer });
          }
          resolve(resp);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  [CUSTOMER_TASK_GET]: ({ commit }, id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`/user/customers/${id}/tasks`)
        .then(response => {
          let data = response.data;
          commit("CUSTOMER_TASK_GET_SUCCESS", {
            customerTasks: data.data.customer_tasks
          });
          resolve(response);
        })
        .catch(error => {
          commit("CUSTOMER_TASK_GET_ERROR", { error: error });

          reject(error);
        });
    });
  },
  [GET_SERVICE_TYPE]: ({ commit }, id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`/user/customers/${id}/servicetype`)
        .then(response => {
          let data = response.data;
          commit("GET_SERVICE_SUCCESS", {
            serviceTypes: data.data.service_types
          });
          resolve(response);
        })
        .catch(error => {
          commit("GET_SERVICE_ERROR", { error: error });

          reject(error);
        });
    });
  },
  [CUSTOMER_TASKS_FILTER]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/user/customer/tasks/filtered", params)
        .then(resp => {
          if (resp.status === 200) {
            let data = resp.data;

            commit("CUSTOMER_TASK_GET_SUCCESS", {
              customerTasks: data.data.customer_tasks
            });
          }
          resolve(resp);
        })
        .catch(error => {
          commit("CUSTOMER_TASK_GET_ERROR", { error: error });

          reject(error);
        });
    });
  },
  [CUSTOMER_CREATE_CLAIM]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/user/customer-claims", data)
        .then(response => {
          let data = response.data;
          commit("CUSTOMER_CLAIM_GET_SUCCESS", {
            claims: data.data.claims
          });
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [CUSTOMER_EDIT_CLAIM]: ({ commit }, { data, claim_id }) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/user/customer-claims/${claim_id}`, data)
        .then(response => {
          let data = response.data;
          commit("CUSTOMER_CLAIM_GET_SUCCESS", {
            claims: data.data.claims
          });
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [CUSTOMER_DELETE_CLAIM]: ({ commit }, { data, claim_id }) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/user/customer-claims-delete/` + claim_id, data)
        .then(response => {
          let data = response.data;
          commit("CUSTOMER_CLAIM_GET_SUCCESS", {
            claims: data.data.claims
          });
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [ALL_CUSTOMER]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get("/user/customers", {
          params: params
        })
        .then(response => {
          let data = response.data;
          commit("ALL_CUSTOMER_SUCCESS", {
            allcustomers: data.data.customers
          });
          resolve(response);
        })
        .catch(error => {
          commit("ALL_CUSTOMER_ERROR", { error: error });
          reject(error);
        });
    });
  },
  [CUSTOMER_GET_ALL_AFTER_SERVICE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`/user/customers/${params.customer_id}/after-services`, {
          params: params
        })
        .then(response => {
          let data = response.data;
          commit("CUSTOMER_GET_ALL_AFTER_SERVICE_SUCCESS", {
            coatingAfters: data.data.coating_afters,
            customerAfterServicePagination: data.paginate
          });

          dispatch("API_PROCESSING", false, { root: true });
          resolve(response);
        })
        .catch(error => {
          commit("CUSTOMER_GET_ALL_AFTER_SERVICE_ERROR", { error: error });

          dispatch("API_PROCESSING", false, { root: true });
          reject(error);
        })
        .finally(() => {
        });
    });
  }
};

const mutations = {
  changeSelectedProject(state, payload) {
    state.selectedProject = payload.id;
  },
  resetSelectedProject(state) {
    state.selectedProject = null;
  },
  [CUSTOMER_GET_ALL_SUCCESS]: (state, params) => {
    state.status = "success";
    state.customers = params.customers;
    state.customerPagination = params.customerPagination;
  },
  [CUSTOMER_GET_SUCCESS]: (state, params) => {
    state.customer = params.customer;
  },
  [CUSTOMER_GET_ERROR]: state => {
    state.status = "error";
  },
  [CUSTOMER_TASK_GET_SUCCESS]: (state, params) => {
    state.status = "success";
    state.customerTasks = params.customerTasks;
  },
  [CUSTOMER_TASK_GET_ERROR]: state => {
    state.status = "error";
  },
  [GET_SERVICE_SUCCESS]: (state, params) => {
    state.status = "success";
    state.serviceTypes = params.serviceTypes;
  },
  [GET_SERVICE_ERROR]: state => {
    state.status = "error";
  },
  [CUSTOMER_CLAIM_GET_SUCCESS]: state => {
    state.status = "success";
  },
  [ALL_CUSTOMER_SUCCESS]: (state, params) => {
    state.status = "success";
    state.allcustomers = params.allcustomers;
  },
  [ALL_CUSTOMER_ERROR]: state => {
    state.status = "error";
  },
  [CUSTOMER_GET_ALL_AFTER_SERVICE_SUCCESS]: (state, params) => {
    state.status = "success";
    state.afterServices = params.coatingAfters;
    state.afterServicePagination = params.customerAfterServicePagination;
  },
  [CUSTOMER_GET_ALL_AFTER_SERVICE_ERROR]: (state) => {
    state.status = 'error'
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
