export const USER_TASKS = "USER_TASKS";
export const USER_ALL_MEMBERS_TASKS = "USER_ALL_MEMBERS_TASKS";
export const ALL_TASKS = "ALL_TASKS";
export const TASKS_FILTER = "TASKS_FILTER";
export const GET_TASK_BY_ID = "GET_TASK_BY_ID";
export const GET_ALL_TASKS_BY_DATE = "GET_ALL_TASKS_BY_DATE";
export const GET_ALL_TASKS_BY_DATE_RANGE = "GET_ALL_TASKS_BY_DATE_RANGE";
export const GET_ALL_TASKS_BY_DATE_SUCCESS = "GET_ALL_TASKS_BY_DATE_SUCCESS";
export const UPDATE_TASK_FINISH_TIME = 'UPDATE_TASK_END_TIME'
export const UPDATE_TASK_FINISH_TIME_MUTATION = 'UPDATE_TASK_FINISH_TIME_MUTATION'
export const GET_TOTAL_TASKS_BY_DATE = 'GET_TOTAL_TASKS_BY_DATE'
export const TOTAL_TASKS_BY_DATE = 'TOTAL_TASKS_BY_DATE'
