<template>
  <v-app-bar color="#F2F2F2">
    <template v-if="$route.name === 'home'">
      <v-toolbar-title>
        <router-link to="/">
          <v-img
            width="150"
            :src="require('@/assets/images/sk-logo-long.png')"
          />
        </router-link>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <!-- <div class="title--text text-heading-2" v-if="user">
        {{ user.full_name }}
      </div>
      <div class="title--text title-honorofics">
        さん
      </div> -->
    </template>

    <template v-else>
      <v-app-bar-nav-icon @click="$router.go(-1)">
        <v-avatar size="25" class="header_avatar">
          <v-btn color="#4F55A7" icon>
            <v-icon size="20">mdi-keyboard-backspace</v-icon>
          </v-btn>
        </v-avatar>
        <!-- <v-icon size="32" color="white">$backButton</v-icon> -->
      </v-app-bar-nav-icon>
      <!-- <v-spacer></v-spacer> -->
      <v-toolbar-title class="title--text text-heading-5 pl-0">
        {{ $t(`title_${this.$route.name}`) }}
      </v-toolbar-title>

      <v-spacer></v-spacer>
    </template>
    <v-app-bar-nav-icon :ripple="false" disabled>
      <v-icon></v-icon>
    </v-app-bar-nav-icon>
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ApplicationBar",
  computed: {
    ...mapGetters(["user"])
  },
  data() {
    return {
      group: null
    };
  }
};
</script>

<style scoped>
.title--text {
  color: #4f55a7;
  font-weight: 700;
}
.title-honorofics {
  font-size: 8px;
}
</style>
