import axios from "@/services/api";
import {
  CLAIM_GET,
  CLAIM_GET_SUCCESS,
  CUSTOMER_CLAIM_GET_BY,
  CUSTOMER_CLAIM_GET_BY_SUCCESS,
  USER_CLAIM_ALL,
  CLAIMS_TAGS,
  CLAIMS_FILTER,
  USER_CLAIM_BY_ID,
  GET_CLAIM_CATEGORIES,
  CLAIM_CATEGORIES_GET_ALL_SUCCESS,
  CLAIM_CATEGORIES_GET_ALL_ERROR
} from "@/store/actions/claim";

const state = {
  claim: null,
  customer_claims: [],
  userclaims: null,
  claimtags: null,
  claimbyid: null,
  claimCategories: [],
};

const getters = {
  getClaim: (state) => state.claim,
  allCustomerClaims: (state) => state.customer_claims,
  alluserclaims: (state) => state.userclaims,
  allclaimtags: (state) => state.claimtags,
  claimById: (state) => state.claimbyid,
  allClaimCategories: state => state.claimCategories,
};

const actions = {
  [CUSTOMER_CLAIM_GET_BY]: ({ commit, dispatch }, params) => {
    return new Promise((resolve) => {
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get("/user/customer-claims", {
          params: params,
        })
        .then((resp) => {
          if (resp.status === 200) {
            const customer_claims = resp.data.data.claims;
            commit("CUSTOMER_CLAIM_GET_BY_SUCCESS", { customer_claims });
          }
          resolve(resp);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  [GET_CLAIM_CATEGORIES]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get('/user/claims-categories', {
          params: params
        })
        .then(response => {
          let data = response.data
          commit('CLAIM_CATEGORIES_GET_ALL_SUCCESS', {
            claimCategories: data.data.claim_categories,
          })
          resolve(response)
        })
        .catch(error => {
          commit('CLAIM_CATEGORIES_GET_ALL_ERROR', { error: error })
          reject(error)
        })
    })
  },
  [CLAIM_GET]: ({ commit, dispatch }, id) => {
    return new Promise((resolve) => {
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get("/user/customer-claims/" + id)
        .then((resp) => {
          if (resp.status === 200) {
            const claim = resp.data.data.claim;
            commit("CLAIM_GET_SUCCESS", { claim });
          }
          resolve(resp);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  [USER_CLAIM_ALL]: ({ commit, dispatch }, params) => {
    return new Promise((resolve) => {
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get("/user/claims", { params: params })
        .then((resp) => {
          if (resp.status === 200) {
            const userclaims = resp.data.data.claims;
            commit("USER_CLAIM_ALL", { userclaims });
          }
          resolve(resp);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  [CLAIMS_TAGS]: ({ commit, dispatch }) => {
    return new Promise((resolve) => {
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get("/user/claim_tag")
        .then((resp) => {
          if (resp.status === 200) {
            const claimtags = resp.data.data.claimtags;
            commit("CLAIMS_TAGS", { claimtags });
          }
          resolve(resp);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  [CLAIMS_FILTER]: ({ commit, dispatch }, params) => {
    return new Promise((resolve) => {
      axios
        .post("/user/claims/filtered", params)
        .then((resp) => {
          if (resp.status === 200) {
            const userclaims = resp.data.data.claims;
            commit("USER_CLAIM_ALL", { userclaims });
          }
          resolve(resp);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  [USER_CLAIM_BY_ID]: ({ commit, dispatch }, id) => {
    return new Promise((resolve) => {
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get("/user/claim/" + id)
        .then((resp) => {
          if (resp.status === 200) {
            const claimbyid = resp.data.data.claims;
            commit("USER_CLAIM_BY_ID", { claimbyid });
          }
          resolve(resp);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
};

const mutations = {
  [CLAIM_GET_SUCCESS]: (state, params) => {
    state.claim = params.claim;
  },
  [CUSTOMER_CLAIM_GET_BY_SUCCESS]: (state, params) => {
    state.customer_claims = params.customer_claims;
  },
  [USER_CLAIM_ALL]: (state, params) => {
    state.userclaims = params.userclaims;
  },
  [CLAIMS_TAGS]: (state, params) => {
    state.claimtags = params.claimtags;
  },
  [USER_CLAIM_BY_ID]: (state, params) => {
    state.claimbyid = params.claimbyid;
  },
  [CLAIM_CATEGORIES_GET_ALL_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.claimCategories = params.claimCategories
  },
  [CLAIM_CATEGORIES_GET_ALL_ERROR]: state => {
    state.status = 'error'
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
