import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import auth from "./modules/auth";
import user from "./modules/user";
import post from "./modules/post";
import notification from "./modules/notification";
import contact from "./modules/contact";
import profile from "./modules/profile";
import settings from "./modules/settings";
import task from "./modules/task";
import clock from "./modules/clock";
import customer from "./modules/customer";
import service from "./modules/service";
import claim from "./modules/claim";
import offDays from "./modules/offDays";
import prefecture from "./modules/prefecture";
import photo from "./modules/photo";
import worktime from "./modules/worktime";
import rest from "./modules/rest";
import tag from "./modules/tag";
import site from "./modules/site";
import visitMemo from "./modules/visitMemo";
import coatingAfter from "./modules/coatingAfter";

Vue.use(Vuex);

const getDefaultState = () => {
  return {
    modalVisible: false,
    modalComponent: null,
    modalTitle: "Dialog Title",
    apiProcessing: false,
    alert: false,
    alertText: "",
    alertSuccess: false,
    errors: {},
    modal: {
      loading: false,
      text:'',
      action: {
        function: null,
        process: {
          color: '',
          text: ''
        },
        cancel: {
          color: '',
          text: ''
        }
      }
    }
  };
};

export default new Vuex.Store({
  state: getDefaultState(),

  mutations: {
    showModal(state, { title, component }) {
      state.modalVisible = true;
      state.modalComponent = component;
      title !== undefined
        ? (state.modalTitle = title)
        : (state.modalTitle = getDefaultState().modalTitle);
    },
    confirmationModal(state, { title, text, action, func }) {
      state.modalVisible = true
      state.modal.text = text
      state.modalTitle = title
      state.modal.action.process = action.process
      state.modal.action.cancel = action.cancel
      state.modal.action.function = func

    },
    showAlert(state, { text, successStatus }) {
      state.alert = true;
      state.alertText = text;
      state.alertSuccess = successStatus;
    },
    hideAlert(state) {
      state.alert = false;
    },
    hideModal(state) {
      state.modalVisible = false;
      state.modalComponent = null;
    },
    startModalLoading(state) {
      state.modal.loading = true;
    },
    stopModalLoading(state) {
      state.modal.loading = false;
    },
    setApiProcessing(state, payload) {
      state.apiProcessing = payload;
    },
    setFormErrors(state, payload) {
      state.errors = payload;
    },
    clearFormErrors(state) {
      state.errors = {};
    }
  },

  actions: {
    API_PROCESSING({ commit }, payload) {
      commit("setApiProcessing", payload);
    },
    SHOW_ALERT({ commit }, payload) {
      commit("showAlert", payload);
    },
  },

  getters: {
    getApiProcessingStatus: (state) => state.apiProcessing,
    getAlertStatus: (state) => state.alert,
    getAlertText: (state) => state.alertText,
    getAlertSuccess: (state) => state.alertSuccess,
    getErrors: (state) => state.errors,
    getModalTitle: (state) => state.modalTitle,
    getModalComponent: (state) => state.modalComponent,
    getModalAction: state => state.modal.action,
    getModalText: state => state.modal.text,
    getModalLoading: state => state.modal.loading
  },

  modules: {
    auth,
    user,
    notification,
    post,
    contact,
    profile,
    settings,
    task,
    clock,
    customer,
    service,
    claim,
    offDays,
    prefecture,
    photo,
    worktime,
    rest,
    tag,
    site,
    visitMemo,
    coatingAfter
  },

  plugins: [createPersistedState()],
});
