import axios from "@/services/api";
import {
  USER_REQUEST,
  USER_REPORT_GET,
  USER_REPORT_GET_SUCCESS,
  USER_REPORT_GET_ERROR,
  USER_CHANGE_PASSWORD,
  USER_CHANGE_PASSWORD_SUCCESS,
  USER_CHANGE_PASSWORD_ERROR,
  USER_GET_ALL,
  USER_GET_ALL_SUCCESS,
  USER_GET_ALL_ERROR
} from "@/store/actions/user";

function initialState() {
  return {
    report: {},
    users: []
  };
}

const state = initialState();

const getters = {
  getUserReport: state => state.report,
  allUsers: state => state.users
};

const actions = {
  [USER_REPORT_GET]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      commit("USER_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/user/reports/top`)
        .then(response => {
          commit("USER_REPORT_GET_SUCCESS", response.data);

          resolve(response);
        })
        .catch(error => {
          commit("USER_REPORT_GET_ERROR");

          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  [USER_CHANGE_PASSWORD]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .put(`/user/settings/change-password`, params)
        .then(response => {
          commit("USER_CHANGE_PASSWORD_SUCCESS", response.data.data);

          resolve(response);
        })
        .catch(error => {
          commit("USER_CHANGE_PASSWORD_ERROR");

          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  [USER_GET_ALL]: ({ commit, dispatch }, params) => {
    dispatch("API_PROCESSING", true, { root: true });
    return new Promise((resolve, reject) => {
      axios
        .get('/user/users', {
          params: params
        })
        .then(response => {
          let data = response.data
          commit('USER_GET_ALL_SUCCESS', {
            users: data.data.users
          })
          resolve(response)
        })
        .catch(error => {
          commit('USER_GET_ALL_ERROR', { error: error })
          reject(error)
        })
    })
  }
};

const mutations = {
  [USER_REQUEST]: state => {
    state.status = "loading";
  },

  [USER_REPORT_GET_SUCCESS]: (state, params) => {
    state.status = "success";
    state.report = params.data;
  },

  [USER_REPORT_GET_ERROR]: state => {
    state.status = "error";
  },

  [USER_CHANGE_PASSWORD_SUCCESS]: state => {
    state.status = "success";
  },

  [USER_CHANGE_PASSWORD_ERROR]: state => {
    state.status = "error";
  },

  [USER_GET_ALL_SUCCESS]: (state, params) => {
    state.status = "success"
    state.users = params.users
  },

  [USER_GET_ALL_ERROR]: state => {
    state.status = "error"
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
