import axios from "@/services/api";
import { CLOCK } from "@/store/actions/clock";

const state = {
  clock: null
};

const getters = {
  allclocks: state => state.clock
};

const actions = {
  [CLOCK]: ({ commit, dispatch }) => {
    return new Promise(resolve => {
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get("/admin/clocks")
        .then(resp => {
          if (resp.status === 200) {
            const clocks = resp.data.data.clocks;
            commit("CLOCK", { clocks });
          }
          resolve(resp);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  }
};

const mutations = {
  [CLOCK]: (state, params) => {
    state.clock = params.clocks;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
