import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    //component: () => import(/* webpackChunkName: "home" */ "../views/Home")
    component: () =>
      import(/* webpackChunkName: "tasks" */ "../views/Tasks.vue"),
    meta: {
      title: "スケジュール",
    },
  },
  {
    path: "/register",
    name: "register",
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/auth/Register"),
    meta: {
      public: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/auth/Login"),
    meta: {
      public: true,
      title: "従業員サイトログイン",
    },
  },
  {
    path: "/forgot-password",
    name: "forgot_password",
    component: () =>
      import(
        /* webpackChunkName: "forgot-password" */ "../views/auth/ForgotPassword"
      ),
    props: (route) => ({ success: route.query.success }),
    meta: {
      public: true,
    },
  },
  {
    path: "/reset-password/:token/:email",
    name: "reset_password",
    component: () =>
      import(
        /* webpackChunkName: "reset-password" */ "../views/auth/ResetPassword"
      ),
    props: (route) => ({ success: route.query.success }),
    meta: {
      public: true,
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/user/Profile"),
    meta: {
      title: "プロフィール",
    },
  },
  {
    path: "/profile/settings",
    name: "settings",
    component: () =>
      import(/* webpackChunkName: "setting" */ "../views/user/Setting"),
  },
  {
    path: "/profile/edit",
    name: "edit_profile",
    component: () =>
      import(
        /* webpackChunkName: "profile-edit" */ "../views/user/EditProfile"
      ),
    meta: {
      title: "プロフィール編集",
    },
  },
  {
    path: "/change-password",
    name: "change_password",
    component: () =>
      import(
        /* webpackChunkName: "change_password" */ "../views/user/ChangePassword"
      ),
  },

  {
    path: "/schedule/my-task",
    name: "tasks",
    component: () =>import(/* webpackChunkName: "tasks" */ "../views/Tasks.vue"),
    meta: {
      title: "私のタスク",
    },
  },
  {
    path: "/schedule/all",
    name: "all-tasks",
    component: () => import("../views/AllTasks.vue"),
    meta: {
      title: "全てのタスク",
    },
  },
  {
    path: "/my-task",
    name: "my-task",
    component: () =>import(/* webpackChunkName: "tasks" */ "../views/task/Tasks.vue"),
    meta: {
      title: "タスク一覧",
    },
  },

  {
    path: "/task/:id",
    name: "tasksDetails",
    component: () =>
      import(/* webpackChunkName: "tasks" */ "../views/TaskDetails.vue"),
    meta: {
      title: "タスク詳細",
    },
  },

  {
    path: "/clock-in/:task_id",
    name: "clockIn",
    component: () =>import(/* webpackChunkName: "clockIn" */ "../views/Clock.vue"),
    meta: {
      title: "イン",
    },
  },

  // {
  //   path: "/clock",
  //   name: "clock",
  //   component: () =>
  //     import(/* webpackChunkName: "clocks" */ "../views/Clock.vue"),
  // },

  {
    path: "/clock-out/:worktime_id",
    name: "clockOut",
    component: () =>
      import(/* webpackChunkName: "clockOut" */ "../views/ClockOut"),
      meta: {
        title: "アウト",
      },
  },

  {
    path: "/worktime",
    name: "worktime",
    component: () =>
      import(
        /* webpackChunkName: "worktime" */ "../views/worktime/Worktime.vue"
      ),
    meta: {
      title: "勤怠一覧",
    },
  },
  {
    path: "/worktime/create",
    name: "worktime-create",
    component: () =>
      import(
        /* webpackChunkName: "worktime-create" */ "../views/worktime/WorktimeCreate.vue"
      ),
      meta: {
        title: "新しい勤怠をつくる",
      },
  },
  {
    path: "/worktime/edit/:id",
    name: "worktime-edit",
    component: () =>
      import(
        /* webpackChunkName: "worktime-edit" */ "../views/worktime/WorktimeEdit.vue"
      ),
      meta: {
        title: "勤怠編集",
      },
  },

  {
    path: "/off-days",
    name: "offDaysApply",
    component: () =>
      import(
        /* webpackChunkName: "clocks" */ "../views/offDays/OffDayApply.vue"
      ),
    meta: {
      title: "休暇申請",
    },
  },
  {
    path: "/customer",
    name: "customer",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "clocks" */ "../views/customer/Customers.vue"
      ),
    meta: {
      title: "顧客一覧",
    },
  },
  {
    path: "/customer",
    name: "customer",
    props: true,
    component: () =>
      import("@/views/customer/Customer.vue"),
    children: [
      {
        path: ":customer_id/site/:site_id/basic",
        name: "customer-basic",
        props: true,
        component: () => import("@/views/customer/Detail/Basic.vue"),
        meta: {
          title: "顧客詳細（基本情報）",
        },
      },
      {
        path: ":customer_id/site/:site_id/visitmemo",
        name: "customer-visitmemo",
        props: true,
        component: () => import("@/views/customer/Detail/VisitMemo.vue"),
        meta: {
          title: "訪問"
        }
      },
      {
        path: ":customer_id/site/:site_id/task",
        name: "customer-task",
        props: true,
        component: () => import("@/views/customer/Detail/Task.vue"),
        meta: {
          title: "顧客詳細（タスク履歴）",
        },
      },
      {
        path: ":customer_id/site/:site_id/photos",
        name: "customer-photos",
        props: true,
        component: () => import("@/views/customer/Detail/Photos.vue"),
        meta: {
          title: "顧客詳細（写真）",
        },
      },
      {
        path: ":customer_id/site/:site_id/claim",
        name: "customer-claim",
        props: true,
        component: () => import("@/views/customer/Detail/Claim.vue"),
        meta: {
          title: "顧客詳細（クレーム）",
        },
      },
      {
        path: ":customer_id/site/:site_id/after-service",
        name: "customer-after-service",
        props: true,
        component: () => import("../views/customer/Detail/AfterService.vue"),
        meta: {
          title: "アフター対応"
        }
      }
    ],
  },

  {
    path: "/post",
    name: "post",
    component: () =>
      import(/* webpackChunkName: "post" */ "../views/post/Posts.vue"),
    meta: {
      title: "お知らせ一覧",
    },
  },

  {
    path: "/post/:id",
    name: "post_detail",
    component: () =>
      import(
        /* webpackChunkName: "post" */ "../components/layout/posts/PostDetail.vue"
      ),
    meta: {
      title: "お知らせ詳細",
    },
  },

  {
    path: "/notifications",
    name: "notifications",
    component: () =>
      import(
        /* webpackChunkName: "notification" */ "../views/notification/Notifications.vue"
      ),
  },
  {
    path: "/notification/:id",
    name: "notification_detail",
    component: () =>
      import(
        /* webpackChunkName: "notification" */ "../components/layout/notifications/NotificationDetail.vue"
      ),
  },
  {
    path: "/claim/create",
    name: "Create",
    component: () =>
      import(/* webpackChunkName: "claim" */ "../views/claim/Create.vue"),
    props: true,
    meta: {
      title: 'クレーム登録'
    }
  },
  {
    path: '/claim/edit/:id',
    name: 'ClaimEdit',
    component: () =>
      import(/* webpackChunkName: "claim" */ "../views/claim/Edit.vue"),
    props: true,
    meta: {
      title: 'クレーム編集'
    }
  },
  {
    path: "/claim",
    name: "ClaimList",
    component: () =>
      import(/* webpackChunkName: "claim" */ "../views/claim/Claims.vue"),
  },
  {
    path: "/claim/:id",
    name: "claim_detail",
    component: () =>
      import(/* webpackChunkName: "claim" */ "../views/claim/Claim.vue"),
    props: true
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

const DEFAULT_TITLE = "総合研装";
router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = `${
      to.meta?.title ? to.meta.title + " | " : ""
    } ${DEFAULT_TITLE}`;
  });
});

router.beforeEach((to, from, next) => {
  const authRequired = !to.matched.some((record) => record.meta.public);
  const loggedIn = store.getters.token;
  store.commit("clearFormErrors");

  if (loggedIn) store.dispatch("PROFILE_GET");

  if (authRequired && !loggedIn) {
    next({
      path: "/login",
      query: { redirect: to.fullPath },
    });
  } else if (!authRequired && loggedIn) {
    next("/");
  }
  next();
});

export default router;
