import axios from "@/services/api";
import {
  USER_TASKS,
  USER_ALL_MEMBERS_TASKS,
  ALL_TASKS,
  TASKS_FILTER,
  GET_TASK_BY_ID,
  GET_ALL_TASKS_BY_DATE,
  GET_ALL_TASKS_BY_DATE_RANGE,
  GET_ALL_TASKS_BY_DATE_SUCCESS,
  UPDATE_TASK_FINISH_TIME,
  UPDATE_TASK_FINISH_TIME_MUTATION,
  GET_TOTAL_TASKS_BY_DATE,
  TOTAL_TASKS_BY_DATE
} from "@/store/actions/task";

const state = {
  tasks: null,
  task: null,
  tasksByDate: null
};

const getters = {
  alltasks: (state) => state.task,
  tasks: (state) => state.tasks,
  task: (state) => state.task,
  tasksByDate: (state) => state.tasksByDate
};

const actions = {
  [USER_TASKS]: ({ commit, dispatch }) => {
    return new Promise((resolve) => {
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get("/user/tasks")
        .then((resp) => {
          if (resp.status === 200) {
            const tasks = resp.data.data.tasks;
            commit("USER_TASKS", { tasks });
          }
          resolve(resp);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [GET_TASK_BY_ID]: ({ commit, dispatch }, params) => {
    return new Promise((resolve) => {
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/user/tasks/${params.id}`, {
          params: params.data,
        })
        .then((resp) => {
          if (resp.status === 200) {
            const tasks = resp.data.data.task;
            commit("USER_TASKS", { tasks });
          }
          resolve(resp);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [USER_ALL_MEMBERS_TASKS]: ({ commit, dispatch }) => {
    return new Promise((resolve) => {
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get("/user/team_member_tasks")
        .then((resp) => {
          if (resp.status === 200) {
            const tasks = resp.data.data.tasks;
            commit("USER_ALL_MEMBERS_TASKS", { tasks });
          }
          resolve(resp);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [ALL_TASKS]: ({ commit, dispatch }) => {
    return new Promise((resolve) => {
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get("/user/all-tasks")
        .then((resp) => {
          if (resp.status === 200) {
            const tasks = resp.data.data.tasks;
            commit("ALL_TASKS", { tasks });
          }
          resolve(resp);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [GET_ALL_TASKS_BY_DATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve) => {
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get("/user/tasks-filter/by-date", {
          params: params,
        })
        .then((resp) => {
          if (resp.status === 200) {
            const tasks = resp.data.data.tasks;
            commit("GET_ALL_TASKS_BY_DATE_SUCCESS", { tasks });
          }
          resolve(resp);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [GET_ALL_TASKS_BY_DATE_RANGE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve) => {
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get("/user/tasks-filter/by-range", {
          params: params,
        })
        .then((resp) => {
          if (resp.status === 200) {
            const tasks = resp.data.data.tasks;
            commit("GET_ALL_TASKS_BY_DATE_SUCCESS", { tasks });
          }
          resolve(resp);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [TASKS_FILTER]: ({ commit, dispatch }, params) => {
    return new Promise((resolve) => {
      axios
        .post("/user/tasks/filtered", params)
        .then((resp) => {
          if (resp.status === 200) {
            const tasks = resp.data.data.tasks_requests;
            commit("USER_TASKS", { tasks });
          }
          resolve(resp);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  [UPDATE_TASK_FINISH_TIME]: ( {commit,dispatch}, {params} ) => {
    return new Promise((resolve) => {
      dispatch("API_PROCESSING", true, { root: true });
      axios.post(`/user/task/${params.id}/finish-time`, params)
      .then(resp => {
        if(resp.status === 200){
          const tasks = resp.data.data.task;
            commit("UPDATE_TASK_FINISH_TIME_MUTATION",  tasks );
        }
        resolve(resp)
      })
      .finally(()=> {
        dispatch("API_PROCESSING", false, { root: true });
      })
    })
  },

  [GET_TOTAL_TASKS_BY_DATE]: ({commit,dispatch}, {params}) => {
    return new Promise((resolve)=> {
      dispatch("API_PROCESSING", true, { root: true });
      axios.get('/user/tasks-count',{
        params: params
      })
      .then((resp)=> {
        if(resp.status === 200) {
          const tasks = resp.data.data.tasks
          commit('TOTAL_TASKS_BY_DATE',tasks)
        }
        resolve(resp)
      })
      .finally(()=> {
        dispatch("API_PROCESSING", false, { root: true });
      })
    })
  }
};

const mutations = {
  [USER_TASKS]: (state, params) => {
    state.task = params.tasks;
  },
  [USER_ALL_MEMBERS_TASKS]: (state, params) => {
    state.task = params.tasks;
  },

  [GET_ALL_TASKS_BY_DATE_SUCCESS]: (state, params) => {
    state.tasks = params.tasks;
  },

  [ALL_TASKS]: (state, params) => {
    state.task = params.tasks;
  },

  [UPDATE_TASK_FINISH_TIME_MUTATION]: (state,params) => {
    state.task.task_end_time = params.task_end_time
  },
  
  [TOTAL_TASKS_BY_DATE] : (state,params) => {
    state.tasksByDate = params
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
