import axios from "@/services/api";
import {
  POST_REQUEST,
  POST_GET,
  POST_GET_SUCCESS,
  POST_GET_ERROR,
  POST_GET_DETAIL,
  POST_GET_DETAIL_SUCCESS,
  POST_GET_DETAIL_ERROR,
  POST_LIST_TITLE
} from "@/store/actions/post";

function initializeState() {
  return {
    posts: [],
    pagination: {},
    postDetails: {},
    postListTitle: ""
  };
}

const state = initializeState();

const getters = {
  getPosts: state => state.posts,
  getPostsPagination: state => state.pagination,
  getPost: state => state.postDetails,
  getPostListTitle: state => state.postListTitle,
  getPostUnreadCount: state => state.unreadCount
};

const actions = {
  [POST_GET]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("POST_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/user/post`, params)
        .then(response => {
          commit("POST_GET_SUCCESS", {
            posts: response.data.data.posts,
            pagination: response.data.paginate
          });

          resolve(response);
        })
        .catch(error => {
          commit("POST_GET_ERROR");

          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  [POST_GET_DETAIL]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("POST_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/user/post/${params.id}`, params)
        .then(response => {
          commit("POST_GET_DETAIL_SUCCESS", response.data);

          resolve(response);
        })
        .catch(error => {
          commit("POST_GET_DETAIL_ERROR");

          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  }
};

const mutations = {
  [POST_REQUEST]: state => {
    state.status = "loading";
  },

  [POST_GET_SUCCESS]: (state, params) => {
    state.status = "success";
    state.posts = params.posts;
    state.pagination = params.pagination;
  },

  [POST_GET_ERROR]: state => {
    state.status = "error";
  },

  [POST_GET_DETAIL_SUCCESS]: (state, params) => {
    state.status = "success";
    state.postDetails = params.data.post;
  },

  [POST_GET_DETAIL_ERROR]: state => {
    state.status = "error";
  },

  [POST_LIST_TITLE]: (state, params) => {
    state.postListTitle = params.postListTitle;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
