<template>
  <v-list two-line class="pa-0 ma-0">
    <template>
      <v-list-item class="bg_color">
        <v-avatar size="25" class="header_avatar">
          <v-btn color="primary" icon @click="goToLogin()">
            <v-icon size="20" color="#ffffff">mdi-keyboard-backspace</v-icon>
          </v-btn>
        </v-avatar>
        <v-list-item-content>
          <v-list-item-title class="header_title pl-3"
            >パスワードの再設定
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-list>
</template>

<script>
export default {
  name: "ForgotPasswordPage",
  methods: {
    goToLogin() {
      this.$router.push("/login");
    }
  }
};
</script>

<style lang="scss" scoped>
.custome_dropdown_padding {
  padding-right: 85px;
}
.header_avatar {
  background-color: #4f55a7;
}
.mdi-view-sequential::before {
  content: "\F728";
  color: #2f80ed;
}
.header_title {
  font-weight: 700;
  font-size: 18px !important;
  color: #4f55a7;
}
.header_subtitle {
  font-family: Noto Sans CJK JP;
  font-size: 14px;
  color: #4f4f4f;
}
.bg_color {
  background-color: #f2f2f2;
}
.v-list--two-line .v-list-item,
.v-list-item--two-line {
  min-height: 55px;
}
</style>
