import axios from "@/services/api";
import {
  SETTINGS_REQUEST,
  SETTINGS_PREFERENCES_GET,
  SETTINGS_PREFERENCES_GET_SUCCESS,
  SETTINGS_PREFERENCES_GET_ERROR,
  SETTINGS_PREFERENCES_UPDATE,
  SETTINGS_PREFERENCES_UPDATE_SUCCESS,
  SETTINGS_PREFERENCES_UPDATE_ERROR
} from "@/store/actions/settings";

function initialState() {
  return {
    preferences: []
  };
}

const state = initialState();

const getters = {
  getSettingPreferences: state => state.preferences
};

const actions = {
  [SETTINGS_PREFERENCES_GET]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      commit("SETTINGS_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/user/settings/preferences`, {
          params: {
            npr: 1
          }
        })
        .then(response => {
          commit("SETTINGS_PREFERENCES_GET_SUCCESS", response.data.data);

          resolve(response);
        })
        .catch(error => {
          commit("SETTINGS_PREFERENCES_GET_ERROR");

          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [SETTINGS_PREFERENCES_UPDATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("SETTINGS_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .put(`/user/settings/preferences`, {
          ...params
        })
        .then(response => {
          commit("SETTINGS_PREFERENCES_UPDATE_SUCCESS", response.data.data);

          resolve(response);
        })
        .catch(error => {
          commit("SETTINGS_PREFERENCES_UPDATE_ERROR");

          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  }
};

const mutations = {
  [SETTINGS_REQUEST]: state => {
    state.status = "loading";
  },

  [SETTINGS_PREFERENCES_GET_SUCCESS]: (state, params) => {
    state.status = "success";
    state.business_year = params.business_year;
    state.previous_term = params.previous_term;
    state.preferences = params.notification;
  },

  [SETTINGS_PREFERENCES_GET_ERROR]: state => {
    state.status = "error";
  },

  [SETTINGS_PREFERENCES_UPDATE_SUCCESS]: state => {
    state.status = "success";
  },

  [SETTINGS_PREFERENCES_UPDATE_ERROR]: state => {
    state.status = "error";
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
