<template>
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      cx="6"
      cy="6.00995"
      rx="6"
      ry="6.00861"
      transform="rotate(180 6 6.00995)"
      fill="currentColor"
    />
    <path
      d="M8.3999 6.01013L4.7999 8.09158L4.7999 3.92869L8.3999 6.01013Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "PlayIcon"
};
</script>

<style scoped></style>
