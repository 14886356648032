import Vue from "vue";
import i18n from "@/plugins/i18n";
import { ValidationProvider, ValidationObserver, extend, localize } from "vee-validate";
import {
  required,
  email,
  max,
  size,
  max_value,
  min_value,
  confirmed
} from "vee-validate/dist/rules.umd";
localize({
  jp: {
    messages: {
      required: i18n.t("field_required_message")
    },
    fields: {
      claimCustomer: {
        required: i18n.t("claim_create_customer")
      },
      claimSite: {
        required: i18n.t("claim_create_site")
      },
      claimService: {
        required: i18n.t("claim_create_service")
      },
      claimTask: {
        required: i18n.t("claim_create_task")
      },
      claimCategory: {
        required: i18n.t("claim_create_category")
      },
      claimDescription: {
        required: i18n.t("claim_create_description")
      },
      claimResponse: {
        required: i18n.t("claim_create_response")
      },
      photoDescription: {
        required: i18n.t('photo_add_description')
      },
      photoDate: {
        required: i18n.t('photo_add_date')
      }
    }
  }
});
localize("jp");

extend("required", {
  ...required
});

extend("max", {
  ...max,
  message: i18n.t("field_max_message")
});

extend("size", {
  ...size,
  message: i18n.t("field_size_message")
});

extend("email", {
  ...email,
  message: i18n.t("field_email_message")
});

extend("max_fl_ratio", {
  ...max_value,
  message: i18n.t("field_max_fl_ratio_message")
});

extend("min_value", {
  ...min_value,
  message: i18n.t("field_min_value_message")
});

extend("password_confirmed", {
  ...confirmed,
  message: i18n.t("field_password_confirmed_message")
});

extend("long_password", {
  message: `パスワードを6文字以上の長さで入力してください。`,
  validate: value => {
    let length = value.length;
    return length >= 6 && length <= 20;
  }
});

extend("verify_password", {
  message: `Password must contain uppercase, lowercase, number and special character`,
  validate: value => {
    var strongRegex = new RegExp(
      "(?=.{8,})((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\\d)(?=.*[a-zA-Z])(?=.*[\\W_])|(?=.*[a-z])(?=.*[A-Z])(?=.*[\\W_])).*"
    );
    return strongRegex.test(value);
  }
});

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
