export const POST_REQUEST = "POST_REQUEST";
export const POST_GET = "POST_GET";
export const POST_GET_SUCCESS = "POST_GET_SUCCESS";
export const POST_GET_ERROR = "POST_GET_ERROR";

export const POST_GET_DETAIL = "POST_GET_DETAIL";
export const POST_GET_DETAIL_SUCCESS = "POST_GET_DETAIL_SUCCESS";
export const POST_GET_DETAIL_ERROR = "POST_GET_DETAIL_ERROR";

export const POST_LIST_TITLE = "POST_LIST_TITLE";
