import axios from "axios";
import {
  WORKTIMES,
  CLOCK_IN,
  CLOCK_OUT,
  WORKTIME_GET,
  WORKTIME_BY_TASK_ID_GET,
  CREATE_NEW_WORKTIME,
  UPDATE_WORKTIME,
  DELETE_WORKTIME,
  COUNT_PENDING_WORKTIME,
  CREATE_WORKTIME_REST,
  WORKTIME_GET_SUCCESS,
  WORKTIME_GET_ERROR,
  WORKTIME_OPERATIONAL_HOURS_GET,
  WORKTIME_OPERATIONAL_HOURS_GET_SUCCESS
} from "@/store/actions/worktime";

const state = {
  worktimes: null,
  worktime: null,
  status: null,
  worktimePendingCount: null,
  operationalHour: {}
};

const getters = {
  worktimes: (state) => state.worktimes,
  worktime: (state) => state.worktime,
  worktimePendingCount: (state) => state.worktimePendingCount,
  operationalHour: (state) => state.operationalHour
};

const actions = {
  [WORKTIMES]: ({ commit }, params) => {
    return new Promise((resolve) => {
      axios.get("user/worktimes", params).then((resp) => {
        if (resp.status === 200) {
          const worktimes = resp.data.data.worktimes;
          commit("WORKTIMES", { worktimes });
        }
        resolve(resp);
      });
    });
  },

  [CLOCK_IN]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`/user/worktimes/clock-in`, data)
        .then((response) => {
          commit("WORKTIME_GET_SUCCESS", response.data);

          resolve(response);
        })
        .catch((error) => {
          commit("WORKTIME_GET_ERROR");

          reject(error);
        });
    });
  },

  [CLOCK_OUT]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`/user/worktimes/clock-out`, data)
        .then((response) => {
          //   commit("WORKTIME_GET_SUCCESS", response.data);

          resolve(response);
        })
        .catch((error) => {
          commit("WORKTIME_GET_ERROR");

          reject(error);
        });
    });
  },

  [WORKTIME_GET]: ({ commit, dispatch }, id) => {
    return new Promise((resolve) => {
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get("/user/worktimes/" + id)
        .then((resp) => {
          if (resp.status === 200) {
            const worktime = resp.data.data.worktime;
            commit("WORKTIME_GET_SUCCESS", { worktime });
          }
          resolve(resp);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [WORKTIME_BY_TASK_ID_GET]: ({ commit, dispatch }, task_id) => {
    return new Promise((resolve) => {
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get("/user/worktimes/by-task-id/" + task_id)
        .then((resp) => {
          if (resp.status === 200) {
            const worktimes = resp.data.data.worktimes;
            // commit("WORKTIME_GET_SUCCESS", { worktime });
            commit("WORKTIMES", { worktimes });
          }
          resolve(resp);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [CREATE_NEW_WORKTIME]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/user/worktimes", params)
        .then((resp) => {
          if (resp.status === 200) {
            const worktime = resp.data.data.data;
            commit("WORKTIME_GET_SUCCESS", { worktime });
          }
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  [UPDATE_WORKTIME]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .put(`/user/worktimes/${params.id}`, params)
        .then((resp) => {
          if (resp.status === 200) {
            // const worktime = resp.data.data.data;
            // commit("WORKTIME_GET_SUCCESS", { worktime });
          }
          resolve(resp);
        })
        .catch((error) => {
          commit("WORKTIME_GET_ERROR");
          reject(error);
        });
    });
  },

  [DELETE_WORKTIME]: ({ commit }, id) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/user/worktimes/` + id)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          commit("WORKTIME_GET_ERROR");
          reject(error);
        });
    });
  },

  [COUNT_PENDING_WORKTIME]: ({ commit }) => {
    return new Promise((resolve) => {
      axios.get("/user/worktimes/pending").then((resp) => {
        if (resp.status === 200) {
          const count = resp.data.data.count;
          commit("COUNT_PENDING_WORKTIME", count);
        }
        resolve(resp);
      });
    });
  },

  [CREATE_WORKTIME_REST]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`/user/worktimes/add-rest`, data)
        .then((response) => {
          // commit("REST_GET_SUCCESS", response.data);

          resolve(response);
        })
        .catch((error) => {
          commit("WORKTIME_GET_ERROR");

          reject(error);
        });
    });
  },

  [WORKTIME_OPERATIONAL_HOURS_GET]: ({ commit }, params) => {
    return new Promise((resolve) => {
      axios.get(`/user/worktimes/operational-hours/${params}`).then((resp) => {
        if (resp.status === 200) {
          const operationalHour = resp.data.data.operational_hour;
          commit("WORKTIME_OPERATIONAL_HOURS_GET_SUCCESS", { operationalHour });
        }
        resolve(resp);
      });
    });
  }
};

const mutations = {
  [WORKTIMES]: (state, params) => {
    state.worktimes = params.worktimes;
  },
  [WORKTIME_GET_SUCCESS]: (state, params) => {
    state.status = "success";
    state.worktime = params.worktime;
  },
  [COUNT_PENDING_WORKTIME]: (state, count) => {
    state.status = "success";
    state.worktimePendingCount = count;
  },
  [WORKTIME_GET_ERROR]: (state) => {
    state.status = "error";
  },
  [WORKTIME_OPERATIONAL_HOURS_GET_SUCCESS]: (state, params) => {
    state.status = "success";
    state.operationalHour = params.operationalHour;
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
