import axios from "@/services/api";
import {
  GET_ALL_TAGS
} from "@/store/actions/tag";

const state = {
  tags: null,
};

const getters = {
  allTags: (state) => state.tags,
};

const actions = {
  [GET_ALL_TAGS]: ({ commit, dispatch }) => {
    return new Promise((resolve) => {
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get("/user/tags")
        .then((resp) => {
          if (resp.status === 200) {
            const tags = resp.data.data;
            commit("GET_ALL_TAGS", { tags });
          }
          resolve(resp);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
};

const mutations = {
  [GET_ALL_TAGS]: (state, params) => {
    state.tags = params.tags;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
