import Vue from "vue";
import Vuetify from "vuetify/lib";
import QuestionMarkIcon from "@/components/icons/QuestionMarkIcon";
import PlayIcon from "@/components/icons/PlayIcon";
import FlagIcon from "@/components/icons/FlagIcon";
import RightArrowIcon from "@/components/icons/RightArrowIcon";
import NewTabIcon from "@/components/icons/NewTabIcon";
import BlueDownArrowIcon from "@/components/icons/BlueDownArrowIcon";
import ReloadIcon from "@/components/icons/ReloadIcon";
import GrayRightArrowIcon from "@/components/icons/GrayRightArrowIcon";
import BackButtonIcon from "@/components/icons/BackButtonIcon";
import GrayDownArrowIcon from "@/components/icons/GrayDownArrowIcon";
import CheckCircleIcon from "@/components/icons/CheckCircleIcon";
import CheckCircleFilledIcon from "@/components/icons/CheckCircleFilledIcon";
import DotIcon from "@/components/icons/DotIcon";
import ja from 'vuetify/lib/locale/ja'
ja.timePicker.am = '午前'
ja.timePicker.pm = '午後'

Vue.use(Vuetify);

const opts = {
  lang: {
    locales: { ja },
    current: 'ja'
  },
  theme: {
    themes: {
      light: {
        primary: "#495BC4",
        secondary: "#0094FF",
        accent: "#69E3FF",
        background: "#FFFFFF",
        editColor: "#1976d2"
      }
    }
  },
  icons: {
    values: {
      question: {
        component: QuestionMarkIcon
      },
      play: {
        component: PlayIcon
      },
      flag: {
        component: FlagIcon
      },
      rightArrow: {
        component: RightArrowIcon
      },
      grayRightArrow: {
        component: GrayRightArrowIcon
      },
      newTab: {
        component: NewTabIcon
      },
      blueDownArrow: {
        component: BlueDownArrowIcon
      },
      grayDownArrow: {
        component: GrayDownArrowIcon
      },
      reload: {
        component: ReloadIcon
      },
      backButton: {
        component: BackButtonIcon
      },
      checkCircleIcon: {
        component: CheckCircleIcon
      },
      checkCircleFilledIcon: {
        component: CheckCircleFilledIcon
      },
      dot: {
        component: DotIcon
      }
    }
  }
};

export default new Vuetify(opts);
