export const CLAIM_GET = "CLAIM_GET";
export const CLAIM_GET_SUCCESS = "CLAIM_GET_SUCCESS";
export const CUSTOMER_CLAIM_GET_BY = "CUSTOMER_CLAIM_GET_BY";
export const CUSTOMER_CLAIM_GET_BY_SUCCESS = "CUSTOMER_CLAIM_GET_BY_SUCCESS";
export const USER_CLAIM_ALL = "USER_CLAIM_ALL";
export const CLAIMS_FILTER = "CLAIMS_FILTER";
export const CLAIMS_TAGS = "CLAIMS_TAGS";
export const USER_CLAIM_BY_ID = "USER_CLAIM_BY_ID";
export const GET_CLAIM_CATEGORIES = "GET_CLAIM_CATEGORIES";
export const CLAIM_CATEGORIES_GET_ALL_SUCCESS = "CLAIM_CATEGORIES_GET_ALL_SUCCESS";
export const CLAIM_CATEGORIES_GET_ALL_ERROR = "CLAIM_CATEGORIES_GET_ALL_ERROR";

