import axios from "axios";
import {
  CREATE_REST,
  REST_GET_SUCCESS,
  REST_GET_ERROR,
} from "@/store/actions/rest";

const state = {
  rests: null,
  rest: null,
  status: null,
};

const getters = {
  rests: (state) => state.rests,
  rest: (state) => state.rest,
};

const actions = {
  [CREATE_REST]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`/user/rests`, data)
        .then((response) => {
          commit("REST_GET_SUCCESS", response.data);

          resolve(response);
        })
        .catch((error) => {
          commit("REST_GET_ERROR");

          reject(error);
        });
    });
  },
};

const mutations = {
  [REST_GET_SUCCESS]: (state, params) => {
    state.status = "success";
    state.worktime = params.rest;
  },
  [REST_GET_ERROR]: (state) => {
    state.status = "error";
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
