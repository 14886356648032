export const AUTH_REQUEST = "AUTH_REQUEST";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_ERROR = "AUTH_ERROR";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_FORGOT = "AUTH_FORGOT";
export const AUTH_FORGOT_SUCCESS = "AUTH_FORGOT_SUCCESS";
export const AUTH_FORGOT_SUCCESS_RESET = "AUTH_FORGOT_SUCCESS_RESET";
export const AUTH_RESET = "AUTH_RESET";
export const AUTH_PROFILE = "AUTH_PROFILE";
export const AUTH_PROFILE_SUCCESS = "AUTH_PROFILE_SUCCESS";
export const AUTH_PROFILE_ERROR = "AUTH_PROFILE_ERROR";
